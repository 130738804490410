@import "../styles/base";

.section {
  &:before {
    content: "";
    width: 1px;
    display: block;
    height: 190px;
    margin-top: -190px;
    // This is to make anchor links not disappear behind fixed header
  }

  @include fromTablet {
    &:before {
      height: 131px;
      margin-top: -131px;
    }
  }
}
