@import '../../../styles/base';

.floatingCart {
  background: $white;
  

  @include untilTablet {
    margin: 0 -10px;
  }

  @include fromTablet {
    position: sticky;
    z-index: 3;
    top: 150px;
    margin-top: 80px;
  }
}

.cart {
  position: relative;

  @include fromTablet {
    @include boxShadow;

    border: 1px solid $grey;
    border-radius: $border-radius;
    margin-bottom: 30px;
    // full screen minus header / margin-top / button at bottom
    max-height: calc(100vh - 131px - 80px - 80px);
    overflow-y: auto;
  }

  .cartItems {
    @include untilTablet {
      display: none;
    }
  }  
}

.visuallyHidden {
  @include visuallyHidden;
}

.toggleBtn {
  @include untilTablet {
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
  }

  @include fromTablet {
    display: none;
  }
}

// mobile cart popup
.cartShow {
  @include untilTablet {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white;
    z-index: 5;
    padding-top: 34px;

    .cartItems {
        display: block;
        max-height: calc(100vh - 54px - 32px);
        overflow-y: auto;
    }  
  }

  .toggleBtn {
    @include untilTablet {
      border: none;
      padding: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: auto;
      left: auto;
      z-index: 4;
      border: none;
      height: 32px;
      width: 32px;
      background: $white;
      backdrop-filter: blur(5px);
      cursor: pointer;
      z-index: 3;

      @include fromTablet {
        top: 20px;
        right: 20px;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        height: 4px;
        width: 26px;
        background: $blue;
        border-radius: 4px;
        position: absolute;
        top: calc(50% - 2px);
        left: 1px;
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}

body:has(.cartShow) {
  header {
    @include untilTablet {
      display: none;
    }
  }
}

.cartHeader {
  display: flex;
  justify-content: space-between;
  background: $blue-dark;
  padding: 15px 20px;
  position: relative;
  z-index: 2;
  color: $white;
  font-size: 1.6rem;
  font-weight: 500;
  
  @include untilTablet {
    font-weight: 900;
  }

  @include fromTablet {
    border-radius: $border-radius $border-radius 0 0;
  }

  b {
    color: $blue-cart;
  }

  a {
    color: $white;
    @include untilTablet {
      pointer-events: none;
    }
  }

  button {
    appearance: none;
    border: none;
    background: none;
    color: $white;
  }


}

.basketLink {
  display: inline-block;
  background: url(../../../images/bg/basket.svg) no-repeat left center;
  padding-left: 20px;
  margin-top: -5px;
}

.count {
  color: $blue-dark;
  display: inline-block;
  background: $blue-cart;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  margin-top: -5px;
  font-size: 1.4rem;
  line-height: 1.55;
}

.visuallyHidden {
  @include visuallyHidden
}

.subtotal {
  font-size: 1.8rem;

  @include untilTablet {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
  }

  @include fromTablet {
    font-size: $font-m;
  }
}

.rowContainer {
  @include untilTablet {
    position: relative;
    max-width: 100vw;
    overflow: auto;
  }
  padding: 15px 18px 15px 20px;
  border-bottom: 1px solid $grey;
}

.sectionHeader {
  h3 {
    margin: 0;
  }
}

.qty {
  color: red;
}

.rowItem {
  @include untilTablet {
    // animation-duration: 2s;
    // animation-name: ticketInOut;
    // position:absolute;
    // background: $green-light;
    // border-bottom: 2px solid $green-dark;
    // left: 0;
    // right: 0;
    // padding: 10px;
    // top: 0;
    // margin: 0;
    // opacity: 1 !important;
    // transform: translateY(-200%);
  }

  margin-top: 5px;
  font-size: $font-s;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  button {
    background: none;
    border: none;
    padding: 0;
    color: $blue;
    text-transform: uppercase;
    cursor: pointer;
    flex: 0 0 80px;

    @include fromTablet {
      flex: 0 0 auto;
    }
  }
}

.rowItemHide {
  @include untilTablet {
    display: none;
  }

  margin-top: 5px;
  font-size: $font-s;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

@keyframes ticketInOut {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  20% {
    opacity: 1;
  }

  60% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(-100%);
  }
}

.qty, .ticketDate {
  color: gray;
}

.loading {
  opacity: 0.4;
}

.loading:first-child{
  opacity: 1;
}

.disabled {
  cursor: not-allowed;
  @include fromTablet {
    font-weight: normal;
  }
}

.btn,
.btnMobile {
  @include btn;

  color: $white;
  background: $blue;
  width: 100%;

  &:hover,
  &:focus {
    background: darken($blue, 10%);
  }

  @include untilTablet {
    display: none;
  }
}

.btnMobile {
  display: none;

  @include untilTablet {
    display: block;
    margin: 20px;
    width: calc(100% - 40px);
  }
}

.emptyMessage {
  padding: 15px 18px 15px 20px;
  text-align: center;
}