@import '../styles/base';

.terms {
    padding: 15px;
    border-radius: $border-radius;
    background: #F2F3F3;
    font-size: 1.3rem;
    margin: 20px 0;

    @include fromTablet {
        padding: 20px;
    }

    h2,
    h3 {
        font-size: 1.3rem;
        font-weight: bold;
        margin: 0 0 5px;
    }

    p {
        margin: 0 0 5px;
    }
}

