@import "../../../../../styles/base";

.yearPicker {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.2rem;

    @include fromTablet {
        font-size: 1.3rem;
        font-weight: bold;
    }
}

.radioWrap {
    margin-left: 5px;

    input {
        margin-right: 5px;
    }
}