@import '../styles/base';

.disclaimer {
    padding: 15px;
    border-radius: $border-radius;
    background: #E6ECEF;
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 20px;

    @include fromTablet {
        padding: 20px;
    }

    p {
        margin: 0;
    }

    a {
        font-weight: bold;
    }
}

.trip {
    @include boxShadow;

    padding: 15px;
    border-radius: $border-radius;
    border: 1px solid $grey;
    font-size: 1.6rem;
    position: relative;

    @include fromTablet {
        padding: 20px 125px 20px 20px;
    }

    h2,
    h3 {
        font-size: 1.8rem;
        margin: 0 0 5px;
    }

    p {
        margin: 0;
    }

    a {
        border-radius: 4px;
        color: $black;
        background: #E6ECEF;
        padding: 10px 20px;
        display: inline-block;
        margin: 10px 0 0;

        @include fromTablet {
            position: absolute;
            right: 20px;
            top: 20px;
        }

        &:hover,
        &:focus {
            background: darken(#E6ECEF, 5%);
        }
    }
}

