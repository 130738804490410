@import "../../../../styles/base";

.modal {
  @include boxShadow;
  max-width: $max-width;
  max-height: 100vh;
  height: 100vh;
  padding: 1rem;
  overflow-y: auto;
  background: $white;

  @include fromTablet {
    max-height: calc(100vh - 4rem);
    border-radius: 12px;
    border: 1px solid $grey;
    padding: 4rem;
    height: auto;
  }

  h2 {
    font-size: 2rem;
    margin-top: 0;
    padding-right: 40px;
    line-height: 1.3;

    @include fromTablet {
      font-size: 3rem;
    }
  }
}

.modalLink {
  color: $blue-light;
  border: none;
  background: none;
  padding: 0;
  text-decoration: underline;
  appearance: none;

  &:hover,
  &:focus {
    cursor: pointer;
    color: darken($blue-light, 10%);
  }
}

.visuallyHidden {
  @include visuallyHidden;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: 32px;
  width: 32px;
  background: $white;
  backdrop-filter: blur(5px);

  @include fromTablet {
    top: 20px;
    right: 20px;
  }
  
  cursor: pointer;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 4px;
    width: 26px;
    background: $blue;
    border-radius: 4px;
    position: absolute;
    top: calc(50% - 2px);
    left: 1px;
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

.btn {
  @include btn;
}

.popupBody {
  display: flex;
  flex-direction: column;
  @include fromTablet {
    flex-direction: row;
    gap: 20px
  }
}

.modalInfo {
  order: 2;
  @include fromTablet {
    order: unset;
    max-height: calc(70vh);
    overflow-y: auto;
  }
}

.modalForm {
  order: 1;
  @include fromTablet {
    order: unset;
    flex: 0 0 450px;
  }
}

.toggle {
  position: relative;
  display: inline-block;
  padding-right: 15px;

  &::after {
    content: "";
    display: block;
    height: 8px;
    width: 8px;
    border: 2px solid $blue;
    border-width: 2px 2px 0 0;
    position: absolute;
    transform: rotate(135deg);
    right: 0;
    top: 4px;
  }

  &.open {
    &::after {
      transform: rotate(-45deg);
      top: 8px;
    } 
  }
}

