@import "../../../../../styles/base";

.selectWrap {
  margin-bottom: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  select {
    appearance: none;
    border: 2px solid $grey;
    border-radius: $border-radius-s;
    padding: 10px 40px 10px 10px;
    font-size: 1.6rem;
    background: url(../../../../../images/bg/select.svg) no-repeat;
    background-position: right 10px center;
    line-height: 1.1;
  }

  label {
    text-align: right;
    font-size: 1.2rem;

    .price {
      display: block;
      font-weight: bold;
      color: $blue;
      font-size: 2rem;
    }
  }
}

.typeAndPrice {
  background-color: white;

}

.dropDown {
  background-color: white;
}