@import "../../../../styles/base";

.btn {
  @include btn;
}

.dateWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.4rem;

  span {
    display: inline-block;
    margin-right: 10px;

    @include fromTablet {
      font-size: 1.3rem;
      font-weight: bold;
    }
  }
}

.ticket {
  text-align: right;
  font-size: 1.2rem;
  margin-bottom: 20px;

  .price {
    display: block;
    font-weight: bold;
    color: $blue;
    font-size: 2rem;
  }
}