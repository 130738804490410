@import "../../../styles/base";

.btn {
  @include btn;
}

.datePicker {
  font-size: 1.6rem;
  border: 2px solid $grey;
  border-radius: $border-radius-s;
  padding: 10px 30px 10px 10px;
  color: $blue;
  font-weight: bold;
  background: url(../../../images/bg/calendar.svg) no-repeat;
  background-position: right 9px top 9px;

  @include fromDesktop {
    font-size: 1.4rem;
    background-size: 14px auto;
    line-height: 1.3;
  }
}



.modal {
  max-height: 100vh;
  border-radius: 0;
  padding: 2rem 2rem 10rem;
  overflow-y: auto;
  background: blueviolet;
}

.modalLink {
  align-self: center;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  line-height: 1.5;
  color: blue;

  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid white;
  height: 32px;
  width: 32px;
  background: blue;
  cursor: pointer;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 3px;
    width: 26px;
    background: white;
    border-radius: 3px;
    position: absolute;
    top: calc(50% - 2px);
    left: 1px;
    transform: rotate(45deg);
  }
}

.popupBody {
  display: flex;
  flex-direction: column;
}