@import "../../../styles/base";

.ticket {
  @include boxShadow;

  border: 1px solid $grey;
  border-radius: $border-radius;
  padding: 15px;
  margin-bottom: 20px;

  @include fromTablet {
    padding: 20px;
  }

  h2 {
    font-size: 2.2rem;
    margin: 0 0 0.5rem;

    @include fromTablet {
      margin: 0 0 1rem;
    }
  }
}

.flexWrap {
  @include fromTablet {
    display: flex;

  }
}

.ticketInfo {
  @include fromTablet {
    display: flex;
    flex: 0 0 67%;
  }
}

  .imgWrap {
    position: relative;
    flex: 0 0 40%;
    overflow: hidden;

    img {
      object-fit: cover;
    }

    @include untilTablet {
      display: none;
    }
  }

  .text {
    border-bottom: 1px solid $grey;
    padding-bottom: 15px;
    margin-bottom: 15px;

    @include fromTablet {
      padding: 10px;
      margin: 0;
      flex: 0 0 60%;
      font-size: 1.2rem;
      border-bottom: none;
      border-right: 1px solid $grey;
    }

    ul {
      padding-left: 15px;
    }
  }

    .parkLogos {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      img {
        flex: 0 0 25%;
        padding: 0 5px;
      }
    }

.ticketPrice {
  @include fromDesktop {
    padding-left: 15px;
    flex: 0 0 33%;
  }
}